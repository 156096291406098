import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

// Sample reviews with client names
const reviews = [
    {
        name: "Alice Johnson",
        review: "This company provided exceptional service and delivered on time! Their attention to detail and communication throughout the process was top-notch."
    },
    {
        name: "Bob Smith",
        review: "I highly recommend their services. They exceeded my expectations and went above and beyond to make sure we were satisfied."
    },
    {
        name: "Charlie Brown",
        review: "Fantastic experience! The team was very professional, responsive, and helpful. Would absolutely work with them again."
    },
    {
        name: "Diana Prince",
        review: "An amazing experience from start to finish. Their commitment to quality and customer satisfaction is truly commendable."
    }
];

const Review = () => {
    const [currentReview, setCurrentReview] = useState(0);
    const [reviewsToShow, setReviewsToShow] = useState(1); // Default to show 1 review

    // Set up useInView hook
    const { ref: serviceRef6, inView: inView6 } = useInView({
        triggerOnce: true, // Trigger animation only once
        threshold: 0.2,    // Trigger when 20% of the section is visible
    });

    useEffect(() => {
        // Set the number of reviews to show based on screen size
        const updateReviewsToShow = () => {
            if (window.innerWidth < 640) {
                setReviewsToShow(1); // sm: 1 review
            } else if (window.innerWidth < 1024) {
                setReviewsToShow(2); // md: 2 reviews
            } else {
                setReviewsToShow(3); // lg: 3 reviews
            }
        };

        updateReviewsToShow(); // Set initial value

        const handleResize = () => {
            updateReviewsToShow(); // Update on window resize
        };

        window.addEventListener('resize', handleResize);

        const intervalId = setInterval(() => {
            setCurrentReview((prev) => (prev + 1) % (reviews.length - reviewsToShow + 1));
        }, 5000); // Change slide every 2 seconds

        return () => {
            clearInterval(intervalId); // Cleanup on unmount
            window.removeEventListener('resize', handleResize); // Cleanup resize listener
        };
    }, [reviewsToShow]);

    // Slice the reviews array to get the current set of reviews
    const displayedReviews = reviews.slice(currentReview, currentReview + reviewsToShow);

    return (
        <section
            id="review-section"
            ref={serviceRef6} // Attach the ref to the section
            className={`py-16 bg-gray-100 text-gray-800 transition-opacity duration-500 ease-out ${inView6 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} // Fade and move animation
        >
            <div className="container mx-auto px-6 lg:px-20">
                <div className="text-center mb-12">
                    <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 text-blue-800">Client Reviews</h2>
                    <p className="text-gray-600 text-base sm:text-lg md:text-xl">
                        Hear what our clients have to say about working with us.
                    </p>
                </div>

                {/* Unique Text-based Review Card Layout */}
                <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
                    {displayedReviews.map((review, index) => (
                        <div
                            key={index}
                            className={`relative w-full max-w-md p-6 bg-white rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105 ${inView6 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
                            style={{ transitionDelay: `${index * 100}ms` }} // Staggered delay for each card
                        >
                            <h3 className="text-lg sm:text-xl font-semibold text-blue-700 text-center mb-1">{review.name}</h3>
                            <p className="text-gray-600 text-center mb-4 leading-relaxed text-sm sm:text-base md:text-lg">{review.review}</p>
                            <div className="absolute bottom-0 left-0 right-0 bg-blue-500 h-2 rounded-lg"></div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Review;
