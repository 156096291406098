import React from 'react';
import { useInView } from 'react-intersection-observer';

const About = () => {
    const { ref: aboutRef, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

    return (
        <section ref={aboutRef} className="relative py-16 bg-gray-800 text-white" id="about-section">
            <div className="container mx-auto px-6 lg:px-20">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">

                    {/* Image Section */}
                    <div className="relative">
                        <div className={`w-full h-80 md:h-96 bg-cover bg-center rounded-lg shadow-lg transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}
                            style={{ backgroundImage: "url('https://images.unsplash.com/photo-1504384308090-c894fdcc538d')" }}>
                        </div>
                        <div className="absolute top-0 left-0 bg-blue-500 h-64 w-64 rounded-full transform -translate-x-16 -translate-y-16 rotate-45 opacity-20"></div>
                    </div>

                    {/* Content Section */}
                    <div className="space-y-6">
                        <h2 className={`text-3xl md:text-4xl font-bold mb-4 transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>About Us</h2>
                        <p className={`text-gray-300 text-lg leading-relaxed transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                            We are a team of creative professionals dedicated to bringing your ideas to life. With expertise in web development, mobile app creation, UI/UX design, and digital marketing, we craft digital experiences that are functional and visually stunning.
                        </p>
                        <p className={`text-gray-300 text-lg leading-relaxed transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                            Our mission is to help businesses grow by delivering tailored solutions that ensure they stand out in an ever-competitive market. Whether it’s through innovative design, effective marketing strategies, or comprehensive consulting, we push the boundaries of technology to deliver exceptional results.
                        </p>
                        <div className={`mt-8 transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                            <a href="#contact" className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-full shadow-lg transition-transform transform hover:scale-105">
                                Contact Us
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
