import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import A1 from "../assets/1a.jpg";
import A2 from "../assets/2a.jpg";
import A3 from "../assets/3a.jpg";
import A4 from "../assets/4a.jpg";

const projects = [
    {
        title: "hqre.io",
        description: "The World's First Investor listing service. We have created the first platform of its kind who brings together investors, wholesalers, lenders and general contractors all under one roof in order to provide a complete and seemless process.",
        image: A1,  // Directly assign the image
        link: "https://hqre.io/",
    },
    {
        title: "Startup Leads",
        description: "The Startupleads, your go-to destination for comprehensive and cutting-edge digital marketing solutions. As a dynamic marketing agency, we specialize in crafting impactful marketing strategies, managing your online presence, and ensuring your brand stands out in the digital landscape.",
        image: A4,  // Directly assign the image
        link: "https://thestartupleads.com",
    },
    {
        title: "Spayzelabs",
        description: "A Website that offer specialized Web3 digital solutions to meet your needs. We were the developer members of this site.",
        image: A3,  // Directly assign the image
        link: "https://spayzelabs.com/",
    },
    {
        title: "RaJ MaYo",
        description: "Raj Mayo is a website dedicated to showcasing beautiful beaches around the world. Discover useful guides and photos to help you find the perfect spot. Choose between light and dark themes for a comfortable browsing experience. Explore the beauty of beaches with Raj Mayo.",
        image: A2,  // Directly assign the image
        link: "https://travelagency09.netlify.app/",
    },
];

const Portfolio = () => {
    const { ref: serviceRef, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

    return (
        <section ref={serviceRef} className="py-16 bg-gray-50 " id="portfolio-section">
            <div className="container mx-auto px-6  ">
                <div className={`text-center mb-12 transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                    <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-800 mb-4">Our Projects</h2>
                    <p className="text-gray-600 text-base sm:text-lg md:text-xl">Take a look at some of the amazing projects we've built so far.</p>
                </div>

                {/* Responsive Grid */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 p-4 ">
                    {projects.map((project, index) => (
                        <div
                            key={index}
                            className={`relative bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 ${inView ? 'transform-none' : 'scale-95'}`}
                        >
                            {/* Image with Scale Effect on Hover */}
                            <div className="relative overflow-hidden">
                                <img
                                    src={project.image}
                                    alt={project.title}
                                    className="w-full h-48 sm:h-60 md:h-72 lg:h-60 xl:h-72 object-cover transition-transform duration-300 hover:scale-110"
                                />
                            </div>
                            <div className="p-4 sm:p-6">
                                <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 mb-2">{project.title}</h3>
                                <p className="text-gray-600 mb-4 text-sm sm:text-base md:text-lg leading-snug">
                                    {project.description.length > 80
                                        ? project.description.substring(0, 80) + "..."
                                        : project.description}
                                </p>
                                {/* Button Hover Effect */}
                                <a
                                    href={project.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-block px-4 py-2 sm:px-6 sm:py-3 bg-blue-500 text-white font-semibold rounded-lg transition-all duration-300 hover:bg-blue-600 hover:shadow-lg"
                                >
                                    View Site <FaExternalLinkAlt className="ml-2 inline" />
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Portfolio;
