import React from 'react';
import { useInView } from 'react-intersection-observer';
import heroImage from "../assets/hero.jpeg"; // Ensure the path to the image is correct
import { Link } from 'react-scroll';

const Hero = () => {
    const { ref: heroRef, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2, // Adjust threshold as needed
    });

    return (
        <div
            className="relative h-[60vh] bg-cover bg-no-repeat bg-center"
            id="home-section"
            style={{ backgroundImage: `url(${heroImage})` }} // Correctly set the background image
        >
            {/* Transparent overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>

            {/* Content */}
            <div
                ref={heroRef}
                className={`relative z-10 flex flex-col items-center justify-center h-full text-center gap-6 px-4 sm:px-6 lg:px-8 transition-opacity duration-1000 ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10'
                    }`}
            >
                <h1 className="text-white text-3xl font-bold">
                    Empowering Communities
                </h1>
                <p className="text-white text-lg max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
                    We are dedicated to providing essential social services that uplift and empower those in need.
                </p>
                <div className="w-96 flex flex-col gap-2 sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                    <Link
                        to="portfolio-section"
                        smooth={true}
                        duration={500}
                        className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 text-lg px-4 border border-gray-400 rounded shadow transition duration-200 ease-in-out transform hover:scale-105">
                        Learn More
                    </Link>
                    <Link
                        to="contact-section"
                        smooth={true}
                        duration={500}
                        className="bg-[#3420D9] text-white hover:bg-[#5243da] font-semibold py-2 text-lg px-4 rounded shadow transition duration-200 ease-in-out transform hover:scale-105">
                        Get Involved
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Hero;
