import React, { useEffect, useState } from 'react';

// Dark blue color for the line
const darkBlue = '#00008B';

const SparklingCursor = () => {
    const [lines, setLines] = useState([]);
    const [lastPosition, setLastPosition] = useState(null);

    const handleMouseMove = (e) => {
        const currentPosition = { x: e.pageX, y: e.pageY };

        if (lastPosition) {
            const newLine = {
                id: Math.random(),
                x1: lastPosition.x,
                y1: lastPosition.y,
                x2: currentPosition.x,
                y2: currentPosition.y,
            };

            setLines((prevLines) => [...prevLines, newLine]);

            // Remove lines after 1 second
            setTimeout(() => {
                setLines((prevLines) => prevLines.filter((line) => line.id !== newLine.id));
            }, 500); 
        }

        setLastPosition(currentPosition);
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [lastPosition]);

    return (
        <div style={{ position: 'relative', zIndex: 9999, pointerEvents: 'none' }}>
            {lines.map((line) => {
                // Calculate angle and distance between two points
                const dx = line.x2 - line.x1;
                const dy = line.y2 - line.y1;
                const distance = Math.sqrt(dx * dx + dy * dy);
                const angle = Math.atan2(dy, dx) * (180 / Math.PI);

                return (
                    <div
                        key={line.id}
                        style={{
                            position: 'absolute',
                            left: `${line.x1}px`,
                            top: `${line.y1}px`,
                            width: `${distance}px`,
                            height: '2px', // Line thickness
                            backgroundColor: darkBlue,
                            transform: `rotate(${angle}deg)`,
                            transformOrigin: '0 0', // Rotate from the start of the line
                            pointerEvents: 'none',
                            transition: 'opacity 1s ease-out', // Smooth fade-out
                            opacity: 1,
                            animation: 'fadeOut 1s forwards', // Trigger fade out
                        }}
                    />
                );
            })}
            {/* Keyframes for smooth fade-out effect */}
            <style jsx>{`
                @keyframes fadeOut {
                    0% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                    }
                }
            `}</style>
        </div>
    );
};

export default SparklingCursor;
