import React, { useEffect } from 'react';

const Modal = ({ isOpen, onClose }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'; // Disable scroll when modal is open
        } else {
            document.body.style.overflow = 'auto'; // Enable scroll when modal is closed
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex justify-center items-center z-50 transition-opacity duration-300 ease-out">
            {/* Background blur effect */}
            <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>

            {/* Modal content with fade-in animation */}
            <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-sm w-full text-center transform transition-transform duration-500 ease-in-out scale-100">
                <h2 className="text-2xl font-semibold mb-4 animate-fadeIn">We're Developing Ourselves</h2>
                <p className="text-gray-600 mb-6 animate-fadeIn">
                    Stay tuned for more updates. We're improving our site for a better experience.
                </p>
                <button
                    onClick={onClose}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition transform hover:scale-105"
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default Modal;
