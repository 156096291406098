import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import useWeb3Forms from '@web3forms/react';

const Contact = () => {
    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const { ref: contactRef, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

    // react-hook-form setup
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting },
    } = useForm({
        mode: 'onTouched',
    });

    // Web3Forms API Key
    const apiKey = 'f0141295-2757-492a-8bd0-b5b3f7c8823c';

    // Web3Forms hook
    const { submit: onSubmit } = useWeb3Forms({
        access_key: apiKey,
        settings: {
            from_name: 'CodeOwl Studio',
            subject: 'New Contact Message from your Website',
        },
        onSuccess: (msg, data) => {
            setIsSuccess(true);
            setMessage('Thank you! Your message has been successfully sent.');
            reset(); // Reset form after successful submission
        },
        onError: (msg, data) => {
            setIsSuccess(false);
            setMessage('Oops! Something went wrong. Please try again later.');
        },
    },
    );

    return (
        <section ref={contactRef} className="relative py-16 bg-white text-gray-800" id="contact-section">
            <div className="container mx-auto px-6 lg:px-20">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">

                    {/* Contact Info */}
                    <div className="space-y-8">
                        <h2 className={`text-4xl font-bold mb-4 text-gray-800 transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>Get In Touch</h2>
                        <p className={`text-lg text-gray-600 transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                            Have questions or inquiries? Reach out to us using any of the options below. We’d love to hear from you.
                        </p>

                        <div className={`flex items-center space-x-4 transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                            <FaPhoneAlt className="text-blue-500 text-xl" />
                            <div>
                                <h4 className="font-semibold">Phone</h4>
                                <p>+92 330 4151733</p>
                            </div>
                        </div>

                        <div className={`flex items-center space-x-4 transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                            <FaEnvelope className="text-blue-500 text-xl" />
                            <div>
                                <h4 className="font-semibold">Email</h4>
                                <p>info@codeowlstudios.com</p>
                            </div>
                        </div>

                        <div className={`flex items-center space-x-4 transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                            <FaMapMarkerAlt className="text-blue-500 text-xl" />
                            <div>
                                <h4 className="font-semibold">Location</h4>
                                <p>Lahore, Pakistan</p>
                            </div>
                        </div>
                    </div>

                    {/* Contact Form */}
                    <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                            <div className={`transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                                <label className="block mb-2 text-sm font-medium text-gray-700">Your Name</label>
                                <input
                                    type="text"
                                    {...register('name', { required: 'Name is required' })}
                                    className="w-full px-4 py-3 rounded-lg bg-white border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter your name"
                                />
                                {errors.name && <span className="text-red-500 text-sm">{errors.name.message}</span>}
                            </div>
                            <div className={`transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                                <label className="block mb-2 text-sm font-medium text-gray-700">Your Email</label>
                                <input
                                    type="email"
                                    {...register('email', {
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                            message: 'Enter a valid email address',
                                        },
                                    })}
                                    className="w-full px-4 py-3 rounded-lg bg-white border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter your email"
                                />
                                {errors.email && <span className="text-red-500 text-sm">{errors.email.message}</span>}
                            </div>
                            <div className={`transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                                <label className="block mb-2 text-sm font-medium text-gray-700">Your Message</label>
                                <textarea
                                    {...register('message', { required: 'Message is required' })}
                                    className="w-full px-4 py-3 rounded-lg bg-white border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                    rows="5"
                                    placeholder="Type your message"
                                ></textarea>
                                {errors.message && <span className="text-red-500 text-sm">{errors.message.message}</span>}
                            </div>
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
                            >
                                {isSubmitting ? 'Sending...' : 'Send Message'}
                            </button>
                        </form>
                        {message && (
                            <p className={`mt-4 ${isSuccess ? 'text-green-500' : 'text-red-500'}`}>{message}</p>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
