import React from 'react';
import { useInView } from 'react-intersection-observer';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { Link } from 'react-scroll';

const Footer = () => {
    const { ref: footerRef, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

    return (
        <footer ref={footerRef} className="bg-gray-800 text-white py-8">
            <div className="container mx-auto px-6 lg:px-20">
                <div className="flex flex-col md:flex-row justify-between items-start mb-8">
                    {/* About Us Section */}
                    <div className="mb-6 md:mb-0 w-full md:w-1/4 lg:w-1/5">
                        <h3 className={`text-xl md:text-2xl font-bold transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                            About Our Work
                        </h3>
                        <p className={`mt-3 text-gray-400 transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'} text-lg`}>
                            We are dedicated to providing essential services that uplift communities and promote social well-being.
                        </p>
                        <p className={`mt-2 text-gray-400 transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'} text-lg`}>
                            Our mission is to foster growth and create impactful solutions for all.
                        </p>
                    </div>

                    {/* Quick Links Section */}
                    <div className="mb-6 md:mb-0 w-full md:w-1/4 lg:w-1/5">
                        <h3 className={`text-xl md:text-2xl font-bold transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                            Quick Links
                        </h3>
                        <ul className="font-medium flex flex-col mt-4 text-white">
                            <li>
                                <Link
                                    to="home-section"
                                    smooth={true}
                                    duration={500}
                                    className="block text-lg md:text-xl py-2 hover:underline cursor-pointer"
                                >
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="review-section"
                                    smooth={true}
                                    duration={500}
                                    className="block text-lg md:text-xl py-2 hover:underline cursor-pointer"
                                >
                                    Review
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="service-section"
                                    smooth={true}
                                    duration={500}
                                    className="block text-lg md:text-xl py-2 hover:underline cursor-pointer"
                                >
                                    Service
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="portfolio-section"
                                    smooth={true}
                                    duration={500}
                                    className="block text-lg md:text-xl py-2 hover:underline cursor-pointer"
                                >
                                    Portfolio
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="about-section"
                                    smooth={true}
                                    duration={500}
                                    className="block text-lg md:text-xl py-2 hover:underline cursor-pointer"
                                >
                                    About
                                </Link>
                            </li>
                        </ul>
                    </div>

                    {/* Contact Information Section */}
                    <div className="mb-6 md:mb-0 w-full md:w-1/4 lg:w-1/5">
                        <h3 className={`text-xl md:text-2xl font-bold transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                            Contact Us
                        </h3>
                        <p className={`mt-3 text-gray-400 transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'} text-lg`}>
                            Email: <a href="mailto:info@codeowlstudios.com" className="text-gray-400 hover:text-white">info@codeowlstudios.com</a>
                        </p>
                        <p className={`mt-2 text-gray-400 transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'} text-lg`}>
                            Phone: <a href="tel:+923304151733" className="text-gray-400 hover:text-white">+92-330-415-1733</a>
                        </p>
                        <p className={`mt-2 text-gray-400 transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'} text-lg`}>
                            Address: <span className="text-gray-400">Lahore, Pakistan</span>
                        </p>
                    </div>

                    {/* Follow Us Section */}
                    <div className="mt-6 md:mt-0 w-full md:w-1/4 lg:w-1/5">
                        <h3 className={`text-xl md:text-2xl font-bold transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                            Follow Us
                        </h3>
                        <div className="flex space-x-6 mt-4">
                            <a href="https://facebook.com" className={`text-gray-400 hover:text-white transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'} text-2xl`}>
                                <FaFacebookF />
                            </a>
                            <a href="https://twitter.com" className={`text-gray-400 hover:text-white transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'} text-2xl`}>
                                <FaTwitter />
                            </a>
                            <a href="https://instagram.com" className={`text-gray-400 hover:text-white transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'} text-2xl`}>
                                <FaInstagram />
                            </a>
                            <a href="https://linkedin.com" className={`text-gray-400 hover:text-white transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'} text-2xl`}>
                                <FaLinkedinIn />
                            </a>
                        </div>
                    </div>
                </div>

                {/* Footer Bottom Section */}
                <div className="border-t border-gray-700 pt-4">
                    <p className={`text-center text-gray-400 text-sm transition-opacity duration-700 ${inView ? 'opacity-100' : 'opacity-0'}`}>
                        &copy; {new Date().getFullYear()} CodeOwl Studio. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
