import React, { useState, useEffect } from 'react';
import Modal from './components/Modal'; // Import your Modal component
import SparklingCursor from './components/SparklingCursor'; // Import your SparklingCursor component
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Portfolio from './components/Portfolio';
import About from './components/About';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Review from './components/Review';
import Service from './components/Service';

function App() {
  const [isModalOpen, setModalOpen] = useState(true);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setModalOpen(true);
  }, []);

  return (
    <div className="relative">
      <SparklingCursor />
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
      <Navbar />
      <Hero />
      <Review />
      <Service />
      <Portfolio />
      <About />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
