import React from 'react';
import { FaPalette, FaBullhorn, FaChartLine, FaMobileAlt, FaLaptopCode, FaHandsHelping } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';

// Services Array
const services = [
    {
        title: "Design",
        description: "Crafting creative and impactful designs that speak your brand language.",
        icon: <FaPalette size={40} />,
    },
    {
        title: "Marketing & Branding",
        description: "Helping your brand stand out with unique strategies and campaigns.",
        icon: <FaBullhorn size={40} />,
    },
    {
        title: "Social Media Marketing",
        description: "Building a strong online presence and engaging your audience on social media.",
        icon: <FaMobileAlt size={40} />,
    },
    {
        title: "Performance Marketing & Ads",
        description: "Boost your business with data-driven marketing strategies and targeted ads.",
        icon: <FaChartLine size={40} />,
    },
    {
        title: "Web Design & Development",
        description: "Building fast, responsive, and beautiful websites with modern technologies.",
        icon: <FaLaptopCode size={40} />,
    },
    {
        title: "Consulting",
        description: "Offering expert advice to help your business overcome challenges and grow.",
        icon: <FaHandsHelping size={40} />,
    },
];

// Service Component with Animations
const Service = () => {
    // Intersection Observer for each service card
    const { ref: serviceRef1, inView: inView1 } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: serviceRef2, inView: inView2 } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: serviceRef3, inView: inView3 } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: serviceRef4, inView: inView4 } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: serviceRef5, inView: inView5 } = useInView({ triggerOnce: true, threshold: 0.2 });
    const { ref: serviceRef6, inView: inView6 } = useInView({ triggerOnce: true, threshold: 0.2 });

    return (
        <section className="py-20 bg-gray-100 relative overflow-hidden" id="service-section">
            {/* Background Circles */}
            <div className="absolute top-0 left-0 w-48 h-48 bg-blue-300 rounded-full opacity-30 filter blur-2xl"></div>
            <div className="absolute bottom-10 right-0 w-64 h-64 bg-purple-300 rounded-full opacity-30 filter blur-2xl"></div>

            <div className="container mx-auto px-4 sm:px-6 lg:px-20 relative z-10">
                {/* Heading */}
                <div className="text-center mb-12">
                    <h2 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-gray-900 mb-4">Our Services</h2>
                    <p className="text-gray-600 text-base sm:text-lg md:text-xl">Explore our range of services tailored to help your business thrive.</p>
                </div>

                {/* Service Cards */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 lg:gap-12 sm:pl-8 sm:pr-8">
                    {services.map((service, index) => {
                        const refArray = [serviceRef1, serviceRef2, serviceRef3, serviceRef4, serviceRef5, serviceRef6];
                        const inViewArray = [inView1, inView2, inView3, inView4, inView5, inView6];
                        return (
                            <div
                                ref={refArray[index]} // attach the observer to each card
                                key={index}
                                className={`relative bg-white p-6 sm:p-8 lg:p-10 rounded-xl shadow-xl transition-all duration-700 ease-out transform hover:scale-105 hover:shadow-2xl ${inViewArray[index]
                                    ? 'opacity-100 translate-y-0'
                                    : 'opacity-0 translate-y-20'
                                    }`}
                            >
                                {/* Icon */}
                                <div className="flex items-center justify-center w-14 h-14 sm:w-16 sm:h-16 mx-auto mb-6 text-white bg-gradient-to-r from-blue-500 to-purple-500 rounded-full shadow-md">
                                    {service.icon}
                                </div>

                                {/* Title */}
                                <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-4 text-center">
                                    {service.title}
                                </h3>

                                {/* Description */}
                                <p className="text-gray-600 text-center text-sm sm:text-base md:text-lg">
                                    {service.description}
                                </p>

                                {/* Decorative Shape at the Bottom */}
                                <div className="absolute bottom-0 left-0 right-0 mx-auto w-1/2 h-2 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full mt-6"></div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default Service;
