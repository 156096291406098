import React, { useState, useEffect } from "react";
import { Link } from "react-scroll"; // Import Link from react-scroll
import { useInView } from 'react-intersection-observer';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    // Set up useInView hook for triggering scroll effects
    const { ref, inView } = useInView({
        triggerOnce: false,
        threshold: 0,
    });

    // Handle scroll event for sticky navbar behavior
    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <nav
            ref={ref}
            className={`bg-white border-gray-200 p-4 transition-all duration-500 ease-out 
                ${scrolled ? 'shadow-lg' : 'shadow-none'}
                ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-full'}`}
        >
            <div className="max-w-screen-xxl flex flex-wrap items-center justify-between md:pl-16 md:pr-16">
                <a href=" " className="flex items-center space-x-3 rtl:space-x-reverse">
                    <span className="self-center text-2xl md:text-2xl font-semibold whitespace-nowrap text-black hover:text-blue-800">
                        CodeOwl
                    </span>
                </a>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    type="button"
                    className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-black rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                    aria-controls="navbar-default"
                    aria-expanded={isOpen ? "true" : "false"}
                >
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>
                <div className={`${isOpen ? "block" : "hidden"} w-full md:block md:w-auto`} id="navbar-default">
                    <ul className="font-medium flex flex-col p-4 md:p-0 mt-4  border border-gray-100 rounded-lg bg-white md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
                        {['Home', 'Review', 'Service', 'Portfolio', 'About'].map((item) => (
                            <li key={item}>
                                <Link
                                    to={`${item.toLowerCase()}-section`} // ID of the section
                                    smooth={true}
                                    duration={500}
                                    className="block text-lg  py-2 px-3 text-black rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 cursor-pointer"
                                    onClick={() => setIsOpen(false)} // Close menu on mobile after click
                                >
                                    {item}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="hidden md:block bg-blue-800 p-2 rounded-lg">
                    <Link
                        to="contact-section" // ID of the contact section
                        smooth={true}
                        duration={500}
                        className="font-bold block text-lg  py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-200 md:p-0 cursor-pointer"
                    >
                        Contact Us
                    </Link>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
